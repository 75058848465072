<template>
    <div id="display-asset-content-level-component">
        <template v-if="show_asset">
            <div :style="`height: ${this.getAssetDisplayOption('imageHeight')}px;`" class="position-relative w-fit">
                <img
                    :src="image_asset"
                    :style="`height: ${getAssetDisplayOption('imageHeight')}px;`"
                    alt="asset"
                    class="mb-0"
                    @error="useFallbackImage()"
                />

                <div
                    :class="`${getClassForAsset('generic-shape')} global-shape`"
                    :style="`
                    height: ${getAssetDisplayOption('liquidHeight')}px;
                    width: ${getAssetDisplayOption('liquidWidth')}px;
                    bottom: ${getAssetDisplayOption('bottom')}px;
                `"
                >
                    <div :class="`w-100 border-level absolute ${getIdForAsset('border')}`" />
                    <div :class="`w-100 liquid-level absolute ${getIdForAsset('liquid')}`" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import useAsset from '@/mixin/useAsset'

export default {
    name: 'DisplayAssetContentLevelComponent',
    mixins: [useAsset],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: false,
        },
        index_model: {
            type: Number,
            required: false,
        },
        scale: {
            type: Number,
            required: false,
            default: 1,
        },
        colorless: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            image_asset: '',
            show_asset: false,
            is_use_fallback_image_asset_model: false,
            asset_display_option: {
                //tank_parallelepipedic
                0: {
                    imageHeight: 120,
                    liquidHeight: 92,
                    liquidWidth: 180,
                    bottom: 14,
                },
                //suiclean
                1: {
                    imageHeight: 160,
                    liquidHeight: 132,
                    liquidWidth: 112,
                    bottom: 4,
                },
                //delaval
                2: {
                    imageHeight: 180,
                    liquidHeight: 158,
                    liquidWidth: 114,
                    bottom: 4,
                },
                //unused
                3: {
                    imageHeight: 180,
                    liquidHeight: 158,
                    liquidWidth: 72,
                    bottom: 4,
                },
                //h2 vertical
                4: {
                    imageHeight: 120,
                    liquidHeight: 72,
                    liquidWidth: 198,
                    bottom: 36,
                },
                //tank_cylindrical_horizontal
                5: {
                    imageHeight: 100,
                    liquidHeight: 76,
                    liquidWidth: 168,
                    bottom: 12,
                },
                //silo
                6: {
                    imageHeight: 180,
                    liquidHeight: 154,
                    liquidWidth: 74,
                    bottom: 14,
                },
                //7: manomètre
                //h2 horizontal
                8: {
                    imageHeight: 120,
                    liquidHeight: 70,
                    liquidWidth: 196,
                    bottom: 36,
                },
                //tank_cylindrical_vertical
                9: {
                    imageHeight: 180,
                    liquidHeight: 154,
                    liquidWidth: 80,
                    bottom: 12,
                },
                //tank_spherical
                10: {
                    imageHeight: 120,
                    liquidHeight: 90,
                    liquidWidth: 90,
                    bottom: 18,
                },
                //tank_cubic
                11: {
                    imageHeight: 120,
                    liquidHeight: 96,
                    liquidWidth: 96,
                    bottom: 11,
                },
                //trash_parallelepipedic
                12: {
                    imageHeight: 120,
                    liquidHeight: 112,
                    liquidWidth: 122,
                    bottom: 4,
                },
                //trash_reference
                13: {
                    imageHeight: 180,
                    liquidHeight: 96,
                    liquidWidth: 100,
                    bottom: 4,
                },
                //trash_cylindrical_vertical & trash_cylindrical_vertical_2
                14: {
                    imageHeight: 140,
                    liquidHeight: 84,
                    liquidWidth: 158,
                    bottom: 4,
                },
                //owl_gg_002
                15: {
                    imageHeight: 140,
                    liquidHeight: 120,
                    liquidWidth: 56,
                    bottom: 6,
                },
                //owl_gg_010
                16: {
                    imageHeight: 120,
                    liquidHeight: 102,
                    liquidWidth: 144,
                    bottom: 11,
                },
                //owl_gg_030
                17: {
                    imageHeight: 120,
                    liquidHeight: 100,
                    liquidWidth: 178,
                    bottom: 11,
                },
                //owl_gg_050, owl_gg_100
                18: {
                    imageHeight: 120,
                    liquidHeight: 100,
                    liquidWidth: 178,
                    bottom: 11,
                },
                //owl_gg_200, owl_gg_300, owl_gg_500
                19: {
                    imageHeight: 120,
                    liquidHeight: 109,
                    liquidWidth: 258,
                    bottom: 2,
                },
            },
        }
    },
    computed: {
        configuration() {
            return JSON.parse(this.asset.configuration)
        },

        configuration_datatable() {
            return JSON.parse(this.asset.configuration_datatable_asset_vision_data)
        },

        asset_model() {
            return this.$store.getters['asset_module/asset_model_by_id'](
                this.asset_module.id_module,
                this.configuration[this.index_model].id_asset_model
            )
        },

        is_configuration_valid() {
            return this.configuration && this.configuration[this.index_model] && this.configuration[this.index_model].is_required
        },
    },
    methods: {
        async useFallbackImage() {
            this.is_use_fallback_image_asset_model = true
            this.image_asset = '/images/asset/fallback_asset.png'
            this.setLiquidHeight()
        },

        getImageAssetToDisplay() {
            this.show_asset = false
            if (this.is_configuration_valid) {
                if (!this.asset_display_option[this.asset_model.front_model_reference]) {
                    this.useFallbackImage()
                } else {
                    this.image_asset = this.asset_model.path_detail_model

                    nextTick(() => {
                        this.setLiquidHeight()
                    })
                }
            }

            this.show_asset = true
        },

        setLiquidHeight() {
            let height = this.getEquivalentPercentHeightLiquidLevel()
            let color = this.getColorLiquid()

            Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('liquid')), function (liquid) {
                liquid.style.height = height + 'px'
                liquid.style.background = color
            })

            Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('border')), function (border) {
                border.style.bottom = height - 2 + 'px'
                border.style.background = color
            })
        },

        getColorLiquid() {
            if (this.colorless) {
                return '#9399a3'
            }

            if (this.is_configuration_valid) {
                return this.$store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                    this.asset_module.id_module,
                    this.asset.id_asset,
                    this.index_model
                ).color
            }

            return this.$store.getters['display_option/display_option'].primary
        },

        getIdForAsset(prefix) {
            return `${this.scale}-${prefix}-${this.asset?.id_asset}-${this.index_model}`
        },

        getClassForAsset(prefix) {
            if (this.is_configuration_valid) {
                return `${prefix}-${this.is_use_fallback_image_asset_model ? 0 : this.asset_model.front_model_reference}`
            }
        },

        getAssetDisplayOption(key) {
            if (this.is_configuration_valid) {
                return (
                    this.asset_display_option[this.is_use_fallback_image_asset_model ? 0 : this.asset_model.front_model_reference][key] *
                    this.scale
                )
            }
        },

        getEquivalentPercentHeightLiquidLevel() {
            if (this.is_configuration_valid) {
                return (
                    (this.getConfigurationDatatableAssetVisionData(this.asset, this.index_model, 'percent') / 100) *
                    this.getAssetDisplayOption('liquidHeight')
                )
            }
        },
    },
    watch: {
        colorless: {
            handler() {
                this.setLiquidHeight()
            },
        },
    },
    created() {
        this.getImageAssetToDisplay()
    },
}
</script>

<style>
.global-shape {
    position: absolute;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
}

.liquid-level,
.border-level {
    transition: height, bottom, opacity !important;
    transition-duration: 0.7s !important;
    transition-timing-function: ease-in-out !important;
}

.liquid-level {
    height: 0;
    bottom: 0;
    opacity: 0.3;
}

.border-level {
    bottom: -4px;
}

.generic-shape-0 {
    border-radius: 4%;
}

.generic-shape-1 {
    border-radius: 8%;
}

.generic-shape-2 {
    border-radius: 0 0 25% 25%;
}

.generic-shape-3 {
    border-radius: 20px;
}

.generic-shape-4 {
    border-radius: 500px;
    transform: translateX(-45%) !important;
}

.generic-shape-5 {
    border-radius: 500px;
}

.generic-shape-6 {
    clip-path: polygon(30% 0%, 70% 0%, 100% 10%, 100% 86%, 60% 100%, 40% 100%, 0% 86%, 0% 10%);
}

.generic-shape-8 {
    clip-path: polygon(
        6% 99%,
        6% 99%,
        4% 96%,
        4% 96%,
        3% 95%,
        3% 95%,
        2% 90%,
        2% 90%,
        1% 86%,
        1% 86%,
        1% 81%,
        1% 81%,
        2% 76%,
        2% 76%,
        2% 72%,
        2% 72%,
        4% 70%,
        4% 70%,
        6% 68%,
        6% 68%,
        8% 67%,
        8% 67%,
        6% 65%,
        6% 65%,
        4% 63%,
        4% 63%,
        3% 62%,
        3% 62%,
        2% 58%,
        2% 58%,
        2% 54%,
        2% 54%,
        1% 50%,
        1% 50%,
        2% 45%,
        2% 45%,
        2% 41%,
        2% 41%,
        3% 38%,
        3% 38%,
        5% 35%,
        5% 35%,
        6% 33%,
        6% 33%,
        6% 32%,
        6% 32%,
        4% 30%,
        4% 30%,
        3% 28%,
        3% 28%,
        2% 24%,
        2% 24%,
        1% 20%,
        1% 20%,
        1% 15%,
        1% 15%,
        1% 11%,
        1% 11%,
        2% 7%,
        2% 7%,
        3% 5%,
        3% 5%,
        4% 2%,
        4% 2%,
        95% 2%,
        95% 2%,
        96% 4%,
        96% 4%,
        98% 6%,
        98% 6%,
        98% 10%,
        98% 10%,
        99% 15%,
        99% 15%,
        99% 19%,
        99% 19%,
        98% 24%,
        98% 24%,
        97% 27%,
        97% 27%,
        96% 30%,
        96% 30%,
        94% 31%,
        94% 31%,
        92% 33%,
        92% 33%,
        94% 35%,
        94% 35%,
        95% 36%,
        95% 36%,
        97% 38%,
        97% 38%,
        98% 41%,
        98% 41%,
        99% 45%,
        99% 45%,
        99% 49%,
        99% 49%,
        99% 53%,
        99% 53%,
        98% 57%,
        98% 57%,
        98% 61%,
        98% 61%,
        97% 63%,
        97% 63%,
        95% 65%,
        95% 65%,
        93% 67%,
        93% 67%,
        95% 69%,
        95% 69%,
        97% 71%,
        97% 71%,
        98% 74%,
        98% 74%,
        99% 78%,
        99% 78%,
        99% 82%,
        99% 82%,
        99% 86%,
        99% 86%,
        98% 90%,
        98% 90%,
        98% 94%,
        98% 94%,
        96% 96%,
        96% 96%,
        95% 98%,
        95% 98%
    );
    transform: translateX(-52%) !important;
}

.generic-shape-9 {
    border-bottom-left-radius: 28% 14%;
    border-bottom-right-radius: 28% 14%;
    border-top-left-radius: 36% 20%;
    border-top-right-radius: 36% 20%;
}

.generic-shape-10 {
    border-radius: 100%;
}

.generic-shape-11 {
    border-radius: 8%;
}

.generic-shape-12 {
    border-radius: 12% 12% 0 0;
}

.generic-shape-13 {
    clip-path: polygon(0% 100%, 0% 100%, 100% 100%, 100% 100%, 100% 36%, 100% 36%, 88% 0%, 88% 0%, 14% 0%, 14% 0%, 0% 37%, 0% 37%);
}

.generic-shape-14 {
    border-radius: 0 0 45% 45% / 0 0 20% 20%;
}

.generic-shape-15,
.generic-shape-16,
.generic-shape-17,
.generic-shape-18,
.generic-shape-19 {
    border-radius: 2%;
}
</style>
